import React from "react";
import { useParams } from "react-router-dom";


const CustomWorkflow = ()=>{
    const { recordId } = useParams();
    const SSOURL = process.env.REACT_APP_SSOURL;
    return(
        <iframe src={`${SSOURL}/apps/workflow/${recordId}`} style={{ width: "100%", height: "100%",border:"none" }} title="Dynamic Content"></iframe>
    )
}

export default CustomWorkflow;