import React, { useEffect, useState, useRef } from "react";
import { message, Table, Skeleton, Modal, Select, Form, Button, Typography } from "antd";
import { LoadingOutlined, CloseOutlined, PlusCircleOutlined, MinusCircleTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useWindowContext, useGlobalContext } from "../../lib/storage";
import { getTabColumns, getTabRecords, getFilterData, getTreeData, formatDisplayField } from "../window/windowUtilities";
import useDebounce from "../../lib/hooks/useDebounce";
import ExpandIcon from "../../assets/images/nestedIcon.svg"
import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css"
import ThemeJson from "../../constants/UIServer.json"
import './list.css'
import { Resizable } from "react-resizable";
import { useParams } from "react-router-dom";
import ReactDragListView from "react-drag-listview";
import { getTabSummary, getWindowInitializationData } from "../../services/generic";
import { FieldReference } from "../../lib/fieldReference";

const { Option } = Select;

const ResizableCell = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const FieldGrouping = (props) => {
  const Themes = ThemeJson;
  const {
    searchKey,
    lastRefreshed,
    treeViewFlag,
    takeColumns,
    filterFlag,
    setFilterFlag,
    summaryData,
    hideAndShowData,
    takeHideAndShowTitles,
    setSelectedRowKeys,
    takeFilters,
    viewFilters,
    expandTreeViewFlag,
    collapseTreeViewFlag,
    treeSearchInput,
    setClearFiltersFlag,
    filterArray,
    setFilterArray,
    setHeaderTab,
    headerTab,
    setColsDataMap,
    setRecordsDataMap
  } = props;
  let index = 1;
  const { tabId } = useParams();
  const [loading, setLoading] = useState(false);
  const [displayKanban, setDisplayKanban] = useState(false);
  // const [headerReferenceList, setHeaderReferenceList] = useState([]);
  const [flag, setFlag] = useState(false)
  const [tabData, setTabData] = useState({});
  const [dataSourceRecords, setDataSourceRecords] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [sorterArray, setSorterArray] = useState({});
  const [finalFilterArray, setFinalFilterArray] = useState([]);
  const [finalSorterArray, setFinalSorterArray] = useState([]);
  const [summaryResponse, setSummaryResponse] = useState({});
  const [reOrderIndexes, setReOrderIndexes] = useState([]);
  const [isLoading, setIsLoading] = useState({});
  const [upsertFlag, setUpsertFlag] = useState(true)
  const { windowStore, setWindowStore } = useWindowContext();
  const windowDefinition = { ...windowStore.windowDefinition };
  const activeTabData = { ...windowStore.activeTabData }
  const [keys, setKeys] = useState(() => {
    if (windowDefinition.keys === undefined) {
      return [];
    } else {
      return windowDefinition.keys;
    }
  });
  const [expandTreeViewData, setExpandTreeViewData] = useState([]);
  const [nestedKeys, setNestedKeys] = useState([]);
  const [nestColumns, setNestColumns] = useState([])
  const [nestedData, setNestedData] = useState({});
  const history = useHistory();
  const { Text } = Typography;

  useEffect(() => {
    let isMounted = true;
    if (windowDefinition.tabs && upsertFlag) {
      setLoading(true);
      const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

      const headerTab = !isObjectEmpty(activeTabData) ? activeTabData : windowDefinition.tabs[windowDefinition.tabs.findIndex((tab) => tab.tablevel === "0")];
      setHeaderTab(headerTab)
      headerTab.fields.sort((a, b) => {
        const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
        const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
        return (x != null ? x : Infinity) - (y != null ? y : Infinity);
      });
      setTabData(headerTab);
      const tabColumnsData = getTabColumns(headerTab);
      let finalFilterArray = [];
      let finalSorterArray = [];
      if (sorterArray.order !== undefined && sorterArray.field !== undefined) {
        finalSorterArray.push(`{'${`sortBy`}':'${sorterArray.field.replace("_iden", "")}','${`sortDirection`}':'${sorterArray.order === "ascend" ? "ASC" : "DESC"}'}`);
      }
      getTabRecords({ windowId: windowDefinition.ad_window_id, ad_tab_id: headerTab.ad_tab_id, startRow: "0", endRow: `${headerTab.pagelimit}`, sortInfo: finalSorterArray.length !== 0 ? finalSorterArray : null }).then(
        (getTabRecordsResponse) => {
          if (isMounted) {
            let filteredData = getFilterData(tabColumnsData, getTabRecordsResponse, headerTab);
            for (let index = 0; index < filteredData.length; index++) {
              if (filteredData[index].filteredValue !== null && filteredData[index].filteredValue !== undefined) {
                if (
                  filteredData[index].baseReferenceId === "17" ||
                  filteredData[index].baseReferenceId === "19" ||
                  filteredData[index].baseReferenceId === "15" ||
                  filteredData[index].baseReferenceId === "16"
                ) {
                  finalFilterArray.push(`{'${`filterBy`}':'${filteredData[index].dataIndex.replace("_iden", "")}','${`filterTerm`}':'[${filteredData[index].filteredValue}]'}`);
                } else {
                  finalFilterArray.push(`{'${`filterBy`}':'${filteredData[index].dataIndex.replace("_iden", "")}','${`filterTerm`}':'${filteredData[index].filteredValue}'}`);
                }
              }
            }
            takeFilters(finalFilterArray);
            setFinalFilterArray(finalFilterArray);
            if (finalFilterArray.length > 0) {
              getTabRecords({
                windowId: windowDefinition.ad_window_id,
                ad_tab_id: headerTab.ad_tab_id,
                search: searchKey,
                startRow: "0",
                endRow: `${headerTab.pagelimit}`,
                filterData: finalFilterArray,
                sortInfo: finalSorterArray.length !== 0 ? finalSorterArray : null,
              }).then((getTabRecordsResponse) => {
                let newFilteredData = getFilterData(tabColumnsData, getTabRecordsResponse, headerTab);
                for (let index = 0; index < newFilteredData.length; index++) {
                  if (newFilteredData[index].filteredValue !== null && newFilteredData[index].filteredValue !== undefined) {
                    if (
                      newFilteredData[index].baseReferenceId === "17" ||
                      newFilteredData[index].baseReferenceId === "19" ||
                      filteredData[index].baseReferenceId === "15" ||
                      filteredData[index].baseReferenceId === "16"
                    ) {
                      finalFilterArray.push(
                        `{'${`filterBy`}':'${newFilteredData[index].dataIndex.replace("_iden", "")}','${`filterTerm`}':'[${newFilteredData[index].filteredValue}]'}`
                      );
                    } else {
                      finalFilterArray.push(
                        `{'${`filterBy`}':'${newFilteredData[index].dataIndex.replace("_iden", "")}','${`filterTerm`}':'${newFilteredData[index].filteredValue}'}`
                      );
                    }
                  }
                }
                for (let index = 0; index < newFilteredData.length; index++) {
                  let title = newFilteredData[index].title;
                  newFilteredData[index].title = <span className="dragHandler">{title}</span>;
                }
                setFinalFilterArray(finalFilterArray);
                takeColumns([...newFilteredData]);
                setColumnsData([...newFilteredData]);
                setColsDataMap([...newFilteredData])
                setDataSourceRecords([...getTabRecordsResponse]);
                setRecordsDataMap([...getTabRecordsResponse])
                setFilterFlag(false);
                setLoading(false);
              });
            } else {
              if (treeViewFlag === false) {
                for (let index = 0; index < filteredData.length; index++) {
                  let title = filteredData[index].title;
                  filteredData[index].title = <span className="dragHandler">{title}</span>;
                }
                takeColumns([...filteredData]);
                setColumnsData([...filteredData]);
                setColsDataMap([...filteredData])
                setDataSourceRecords([...getTabRecordsResponse]);
                setRecordsDataMap([...getTabRecordsResponse])
                setLoading(false);
              } else {
                // getTabRecords({ ad_tab_id: headerTab.ad_tab_id, startRow: "0", endRow: "500", sortInfo : finalSorterArray.length !== 0 ? finalSorterArray : null }).then((getTabRecordsResponse) => {
                for (let index = 0; index < filteredData.length; index++) {
                  let title = filteredData[index].title;
                  filteredData[index].title = <span className="dragHandler">{title}</span>;
                }
                takeColumns([...filteredData]);
                setColumnsData([...filteredData]);
                setExpandTreeViewData([...getTabRecordsResponse]);
                const treeRowData = getTreeData(getTabRecordsResponse, windowDefinition.tabs);
                setDataSourceRecords([...treeRowData]);
                setLoading(false);
                // });
              }
            }
          }
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [treeViewFlag, filterArray, tabId, upsertFlag]);

  const debouncedTreeSearchKey = useDebounce(treeSearchInput, 350);
  useEffect(() => {
    if (debouncedTreeSearchKey !== "") {
      getTreesearchData(debouncedTreeSearchKey);
    } else {
      setKeys([]);
    };
  }, [debouncedTreeSearchKey]);

  const getTreesearchData = (input) => {
    setLoading(true);
    if (treeViewFlag === true) {
      let arr = [];
      let mainData = [...expandTreeViewData];
      mainData.forEach(record => {
        let flag = "N";
        Object.values(record).forEach(value => {
          if (value !== null && typeof (value) !== "number" && typeof (value) !== "object") {
            if (value.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
              flag = "Y"
            };
          };
        });
        if (flag === "Y") {
          arr.push(record);
        };
      });

      let arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [], arr7 = [], arr8 = [], arr9 = [], arr10 = [];
      arr.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr1.push(row);
            }
          })
        }
      });
      arr1.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr2.push(row);
            }
          })
        }
      });
      arr2.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr3.push(row);
            }
          })
        }
      });
      arr3.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr4.push(row);
            }
          })
        }
      });
      arr4.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr5.push(row);
            }
          })
        }
      });
      arr5.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr6.push(row);
            }
          })
        }
      });
      arr6.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr7.push(row);
            }
          })
        }
      });
      arr7.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr8.push(row);
            }
          })
        }
      });
      arr8.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr9.push(row);
            }
          })
        }
      });
      arr9.forEach(item => {
        if (item.tree_field_id !== null) {
          mainData.forEach(row => {
            if (row.recordId === item.tree_field_id) {
              arr10.push(row);
            }
          })
        }
      });
      let finalArr = [...arr, ...arr1, ...arr2, ...arr3, ...arr4, ...arr5, ...arr6, ...arr7, ...arr8, ...arr9, ...arr10];
      let key = [];
      finalArr.forEach(item => {
        if (key.includes(item.key)) {

        } else {
          key.push(item.key);
        };
      });
      setKeys(key);
      const getTreeMainData = (data) => {
        const toTree = (arr) => {
          const arrMap = new Map(arr.map(item => [item.recordId, item]));
          const tree = [];
          for (let index = 0; index < arr.length; index++) {
            const item = arr[index];
            if (item.tree_field_id) {
              const parentItem = arrMap.get(item.tree_field_id);
              if (parentItem) {
                const { children } = parentItem;
                if (children) {
                  parentItem.children.push(item);
                } else {
                  parentItem.children = [item];
                }
              }
            } else {
              tree.push(item);
            }
          }
          return tree;
        }
        const treeRowData = toTree(data);
        return treeRowData;
      };
      mainData.map(item => {
        delete item['children'];
      });
      let treeRowData = getTreeMainData(mainData, windowDefinition.tabs);
      setDataSourceRecords([...treeRowData]);
      setLoading(false);
    };
  };

  useEffect(() => {
    if (viewFilters !== "") {
      const data = JSON.parse(viewFilters.replace(/'/g, '"'));
      const windowDef = { ...windowDefinition };
      if (windowDef.tabs) {
        const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

        const headerTab = !isObjectEmpty(activeTabData) ? activeTabData : windowDefinition.tabs[windowDefinition.tabs.findIndex((tab) => tab.tablevel === "0")];
        headerTab.fields.sort((a, b) => {
          const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
          const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
          return (x != null ? x : Infinity) - (y != null ? y : Infinity);
        });
        for (let index1 = 0; index1 < headerTab.fields.length; index1++) {
          headerTab.fields[index1].filters = null;
        }
        for (let index1 = 0; index1 < headerTab.fields.length; index1++) {
          if (
            headerTab.fields[index1].nt_base_reference_id !== FieldReference.Button &&
            headerTab.fields[index1].isdisplayed === "Y" &&
            headerTab.fields[index1].isactive === "Y" &&
            headerTab.fields[index1].showinrelation === "Y"
          ) {
            for (let index2 = 0; index2 < data.length; index2++) {
              if (data[index2].filterBy.replace(/[^a-zA-Z0-9-, ]/g, "") === headerTab.fields[index1].ad_field_id) {
                headerTab.fields[index1].filters = [data[index2].filterTerm.replace(/[^a-zA-Z0-9-, ]/g, "")];
              }
            }
          } else {
            headerTab.fields[index1].filters = null;
          }
        }
      }
      setWindowStore({ windowDefinition: windowDef });
      setFilterArray(viewFilters);
    }
  }, [viewFilters]);

  useEffect(() => {
    if (windowDefinition.tabs) {
      const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

      const headerTab = !isObjectEmpty(activeTabData) ? activeTabData : windowDefinition.tabs[windowDefinition.tabs.findIndex((tab) => tab.tablevel === "0")];
      headerTab.fields.sort((a, b) => {
        const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
        const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
        return (x != null ? x : Infinity) - (y != null ? y : Infinity);
      });
      setTabData(headerTab);
      const tabColumnsData = getTabColumns(headerTab);
      for (let index = 0; index < tabColumnsData.length; index++) {
        let title = tabColumnsData[index].title;
        tabColumnsData[index].title = <span className="dragHandler">{title}</span>;
      }
      if (dataSourceRecords.length > 0) {
        let filteredData = getFilterData(tabColumnsData, dataSourceRecords, headerTab);
        takeColumns([...filteredData]);
        setColumnsData([...filteredData]);
        setColsDataMap([...filteredData])
      }
    }
  }, [hideAndShowData, reOrderIndexes]);


  const [rowSelectionKeys, setRowSelectionKeys] = useState([]);
  const onSelectChange = (keys, selectedRows) => {
    setRowSelectionKeys([...keys]);
    setSelectedRowKeys([...selectedRows]);
  };



  useEffect(() => {
    index = 1;
    const windowDef = { ...windowDefinition };
    if (windowDef.tabs) {
      const headerTab = windowDef.tabs[windowDef.tabs.findIndex((tab) => tab.tablevel === "0")];
      headerTab.fields.sort((a, b) => {
        const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
        const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
        return (x != null ? x : Infinity) - (y != null ? y : Infinity);
      });
      for (let index1 = 0; index1 < headerTab.fields.length; index1++) {
        if (
          headerTab.fields[index1].nt_base_reference_id !== FieldReference.Button &&
          headerTab.fields[index1].isdisplayed === "Y" &&
          headerTab.fields[index1].isactive === "Y" &&
          headerTab.fields[index1].showinrelation === "Y"
        ) {
          for (let index2 = 0; index2 < columnsData.length; index2++) {
            if (columnsData[index2].dataIndex.replace("_iden", "") === headerTab.fields[index1].ad_field_id) {
              headerTab.fields[index1].gridlength = columnsData[index2].width;
            }
          }
        }
      }
    }
    setWindowStore({ windowDefinition: windowDef });
  }, [columnsData]);

  const rowSelection = {
    selectedRowKeys: rowSelectionKeys,
    onChange: onSelectChange,
    hideSelectAll: false,
    fixed: true,
  };

  const debouncedSearchKey = useDebounce(searchKey, 350);
  useEffect(() => {
    if (searchKey !== null) {
      if (debouncedSearchKey || debouncedSearchKey === "") {
        getLineSearchDataValue(debouncedSearchKey.trim());
      }
    }
  }, [debouncedSearchKey]);

  const getLineSearchDataValue = async (SearchValue) => {
    setLoading(true);
    const getTabRecordsResponse = await getTabRecords({ windowId: windowDefinition.ad_window_id, ad_tab_id: tabData.ad_tab_id, search: SearchValue, filterData: finalFilterArray, startRow: "0", endRow: `${tabData.pagelimit}` });
    setDataSourceRecords([...getTabRecordsResponse]);
    setLoading(false);
  };

  let scrollLeft;
  let maxScroll;
  let currentScroll;


  const fetchMoreData = (event) => {
    maxScroll = event.target.scrollHeight - event.target.clientHeight;
    currentScroll = event.target.scrollTop;
    if (scrollLeft !== event.target.scrollLeft) {
      scrollLeft = event.target.scrollLeft;
      return null;
    }

    if (Math.round(currentScroll) >= Math.round(maxScroll * 0.8) && Math.round(currentScroll) <= Math.round(maxScroll * 0.85)) {
      index = 1;
    }

    if (Math.round(currentScroll) >= Math.round(maxScroll) - 10 && index === 1) {
      setLoading(true);
      const tabColumnsData = getTabColumns(tabData);
      const recordOffset = dataSourceRecords.length;
      if (finalFilterArray.length > 0) {
        getTabRecords({
          windowId: windowDefinition.ad_window_id,
          ad_tab_id: tabData.ad_tab_id,
          search: searchKey,
          startRow: `${recordOffset}`,
          endRow: `${tabData.pagelimit}`,
          filterData: finalFilterArray,
          sortInfo: finalSorterArray.length !== 0 ? finalSorterArray : null,
        })
          .then((getTabRecordsResponse) => {
            if (getTabRecordsResponse.length > 0) {
              const newSourceRecords = dataSourceRecords.concat(getTabRecordsResponse);
              let newFilteredData = getFilterData(tabColumnsData, newSourceRecords, tabData);
              setDataSourceRecords([...newSourceRecords]);
              takeColumns([...newFilteredData]);
              setColumnsData([...newFilteredData]);
              setColsDataMap([...newFilteredData])
            } else {
              message.destroy();
              message.info({
                content: <>
                  <p style={{ marginBottom: 0, color: "#0C173A", fontFamily: "Inter", fontWeight: 500, fontSize: "1vw", textAlign: "start" }}>No more records !</p>
                  <p>You have reached the end of the list</p>
                </>
              });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        getTabRecords({
          windowId: windowDefinition.ad_window_id,
          ad_tab_id: tabData.ad_tab_id,
          search: searchKey,
          filterData: finalFilterArray,
          startRow: `${recordOffset}`,
          endRow: `${tabData.pagelimit}`,
          sortInfo: finalSorterArray.length !== 0 ? finalSorterArray : null,
        })
          .then((getTabRecordsResponse) => {
            if (getTabRecordsResponse.length > 0) {
              const newSourceRecords = dataSourceRecords.concat(getTabRecordsResponse);
              let newFilteredData = getFilterData(tabColumnsData, newSourceRecords, tabData);
              setDataSourceRecords([...newSourceRecords]);
              takeColumns([...newFilteredData]);
              setColumnsData([...newFilteredData]);
              setColsDataMap([...newFilteredData])
            } else {
              message.destroy();
              message.info({
                content: <>
                  <p style={{ marginBottom: 0, color: "#0C173A", fontFamily: "Inter", fontWeight: 500, fontSize: "1vw", textAlign: "start" }}>No more records !</p>
                  <p style={{ marginBottom: 0, color: "#0C173A", fontWeight: 300, fontSize: "0.9vw", fontFamily: "Inter" }}>You have reached the end of the list</p>
                </>
              })
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const lastTableRef = useRef(null);
  const containerRef = useRef(null);
  
  useEffect(() => {
    const lastTableBody = lastTableRef.current?.querySelector(".ant-table-body");
    const container = containerRef.current;
  
    // Determine if the last table has a vertical scrollbar
    const isLastTableScrollable = lastTableBody && lastTableBody.scrollHeight > lastTableBody.clientHeight;
  
    const scrollElement = isLastTableScrollable ? lastTableBody : container;
    
    const handleScroll = (e) => {
      if (scrollElement.scrollTop + scrollElement.clientHeight >= isLastTableScrollable?scrollElement.scrollHeight:scrollElement.scrollElement-150) {
        console.log("Fetching more data...");
        fetchMoreData(e);
      }
    };
  
    // Add the scroll listener to the determined element
    if (scrollElement && !treeViewFlag) {
      scrollElement.addEventListener("scroll", handleScroll);
    }
  
    return () => {
      // Remove the scroll listener on cleanup
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      } 
    };
  }, [fetchMoreData, treeViewFlag]);
  

  useEffect(() => {
    if (lastRefreshed) {
      getLineSearchDataValue(searchKey);
    }
  }, [lastRefreshed]);

  const handleOnExpand = (expanded, record) => {
    if (expanded === true) {
      setKeys([...keys, record.key]);
    } else {
      let arrKeys = [];
      for (let index = 0; index < keys.length; index++) {
        if (record.key !== keys[index]) {
          arrKeys.push(keys[index]);
        }
      }
      setKeys(arrKeys);
    }
  };

  const expandedRowRender = (record) => {
    setFlag(true)
    if (windowDefinition.tabs) {
      const firstLevelTabs = windowDefinition.tabs[windowDefinition.tabs.findIndex((tab) => tab.tablevel === "1")];
      firstLevelTabs.fields.sort((p, c) => parseInt(p.grid_seqno) - parseInt(c.grid_seqno));
      const tabColumnsData = getTabColumns(firstLevelTabs);
      const data = nestedData[record.recordId];

      return (
        <Table
          style={{ fontSize: "12px" }}
          // className="nestTable"
          size="small"
          sticky={true}
          components={components}
          scroll={{ y: "60vh" }}
          columns={tabColumnsData}
          dataSource={nestedData[record.recordId]}
          loading={isLoading[record.recordId] && !data}
          pagination={false}
        />
      );
    }
  };


  const handleExpand = (expanded, record) => {
    setIsLoading({
      [record.recordId]: true,
    });
    if (expanded === true) {
      getTabRecords({ windowId: windowDefinition.ad_window_id, ad_tab_id: tabData.child_tab_id, parentTabId: tabData.ad_tab_id, parentRecordID: record.recordId, startRow: "0", endRow: `${tabData.pagelimit}` }).then(
        (getTabRecordsResponse) => {
          setNestedData((state) => ({
            ...state,
            [record.recordId]: getTabRecordsResponse,
          }));
        }
      );
      setNestedKeys([...nestedKeys, record.key]);
    } else {
      let arrKeys = [];
      for (let index = 0; index < nestedKeys.length; index++) {
        if (record.key !== nestedKeys[index]) {
          arrKeys.push(nestedKeys[index]);
        }
      }
      setNestedKeys(arrKeys);
    }
  };

  useEffect(() => {
    const windowDef = { ...windowDefinition };
    if (windowDef) {
      windowDef.keys = keys;
    }
    setWindowStore({ windowDefinition: windowDef });
  }, [keys]);

  const handleTableChange = (pagination, filters, sorter) => {
    const windowDef = { ...windowDefinition };
    if (windowDef.tabs) {
      const headerTab = windowDef.tabs[windowDef.tabs.findIndex((tab) => tab.tablevel === "0")];
      headerTab.fields.sort((a, b) => {
        const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
        const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
        return (x != null ? x : Infinity) - (y != null ? y : Infinity);
      });
      for (let index1 = 0; index1 < headerTab.fields.length; index1++) {
        if (
          headerTab.fields[index1].nt_base_reference_id !== FieldReference.Button &&
          // headerTab.fields[index1].isdisplayed === "Y" &&
          headerTab.fields[index1].isactive === "Y"
          // headerTab.fields[index1].showinrelation === "Y"
        ) {
          for (let index2 = 0; index2 < Object.keys(filters).length; index2++) {
            if (Object.keys(filters)[index2].replace("_iden", "") === headerTab.fields[index1].ad_field_id) {
              if (Object.values(filters)[index2] !== undefined) {
                headerTab.fields[index1].filters = Object.values(filters)[index2];
              } else {
                headerTab.fields[index1].filters = null;
              }
            }
          }
        } else {
          headerTab.fields[index1].filters = null;
        }
      }
    }
    setWindowStore({ windowDefinition: windowDef });
    setSorterArray(sorter);
    setFilterArray(filters);
  };

  useEffect(() => {
    if (filterFlag) {
      const windowDef = { ...windowDefinition };
      if (windowDef.tabs) {
        const headerTab = windowDef.tabs[windowDef.tabs.findIndex((tab) => tab.tablevel === "0")];
        headerTab.fields.sort((a, b) => {
          const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
          const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
          return (x != null ? x : Infinity) - (y != null ? y : Infinity);
        });
        for (let index1 = 0; index1 < headerTab.fields.length; index1++) {
          if (
            headerTab.fields[index1].nt_base_reference_id !== FieldReference.Button &&
            headerTab.fields[index1].isdisplayed === "Y" &&
            headerTab.fields[index1].isactive === "Y" &&
            headerTab.fields[index1].showinrelation === "Y"
          ) {
            headerTab.fields[index1].filters = null;
          }
        }
      }
      setWindowStore({ windowDefinition: windowDef });
      setFilterArray({});
    }
  }, [filterFlag]);

  const components = {
    header: {
      cell: ResizableCell,
    },
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let startIndex, endIndex;
      if (tabData.enablenestedtab === "Y") {
        startIndex = fromIndex - 2;
        endIndex = toIndex - 2;
      } else {
        startIndex = fromIndex - 1;
        endIndex = toIndex - 1;
      }
      let tempDataindexes = [];
      for (let index = 0; index < columnsData.length; index++) {
        if (index === startIndex) {
          tempDataindexes.push(columnsData[index].dataIndex.replace("_iden", ""));
        }
      }
      for (let index = 0; index < columnsData.length; index++) {
        if (index === endIndex) {
          tempDataindexes.push(columnsData[index].dataIndex.replace("_iden", ""));
        }
      }
      let index1, index2;
      const windowDef = { ...windowDefinition };
      if (windowDef.tabs) {
        const headerTab = windowDef.tabs[windowDef.tabs.findIndex((tab) => tab.tablevel === "0")];
        headerTab.fields.sort((a, b) => {
          const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
          const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
          return (x != null ? x : Infinity) - (y != null ? y : Infinity);
        });
        for (let index = 0; index < headerTab.fields.length; index++) {
          if (headerTab.fields[index].nt_base_reference_id !== FieldReference.Button && headerTab.fields[index].isdisplayed === "Y" && headerTab.fields[index].isactive === "Y") {
            if (tempDataindexes[0] === headerTab.fields[index].ad_field_id) {
              index1 = index;
            }
          }
        }
        for (let index = 0; index < headerTab.fields.length; index++) {
          if (headerTab.fields[index].nt_base_reference_id !== FieldReference.Button && headerTab.fields[index].isdisplayed === "Y" && headerTab.fields[index].isactive === "Y") {
            if (tempDataindexes[1] === headerTab.fields[index].ad_field_id) {
              index2 = index;
            }
          }
        }
        const item = headerTab.fields.splice(index1, 1)[0];
        headerTab.fields.splice(index2, 0, item);
        for (let index = 0; index < headerTab.fields.length; index++) {
          if (headerTab.fields[index].nt_base_reference_id !== FieldReference.Button && headerTab.fields[index].isdisplayed === "Y" && headerTab.fields[index].isactive === "Y") {
            headerTab.fields[index].grid_seqno = index;
          }
        }
      }
      setWindowStore({ windowDefinition: windowDef });
      takeHideAndShowTitles([index1, index2]);
      setReOrderIndexes([index1, index2]);
    },
    nodeSelector: "th",
    handleSelector: ".dragHandler",
    ignoreSelector: "react-resizable-handle",
  };

  useEffect(() => {
    if (windowDefinition.tabs) {
      const headerTab = windowDefinition.tabs[windowDefinition.tabs.findIndex((tab) => tab.tablevel === "0")];
      if (Object.values(summaryData).length > 0) {
        let finalSummaryData = JSON.stringify(summaryData).replace(/"/g, '\\"');
        getTabSummary({ windowId: windowDefinition.ad_window_id, tabId: headerTab.ad_tab_id, filterData: finalFilterArray, summaryData: finalSummaryData }).then((getTabSummaryResponse) => {
          setSummaryResponse(JSON.parse(getTabSummaryResponse));
        });
      } else {
        setSummaryResponse({});
      }
    }
  }, [summaryData, finalFilterArray]);

  const summary = () => {
    let arr = [];
    const userPreferences = JSON.parse(localStorage.getItem("userPreferences"));
    for (let index1 = 0; index1 < Object.values(summaryResponse).length; index1++) {
      for (let index2 = 0; index2 < Object.values(summaryResponse)[index1].length; index2++) {
        for (let index3 = 0; index3 < Object.values(Object.values(summaryResponse)[index1][index2]).length; index3++) {
          const fixedValue = parseInt(userPreferences.decimalPlaces);
          const numberValue = parseFloat(Object.values(Object.values(summaryResponse)[index1][index2])[index3]).toFixed(fixedValue);
          if (!isNaN(numberValue)) {
            Object.values(summaryResponse)[index1][index2][Object.keys(Object.values(summaryResponse)[index1][index2])[index3]] = numberValue;
          }
        }
      }
    }
    if (Object.keys(summaryResponse).length > 0) {
      for (let index1 = 0; index1 < Object.values(summaryResponse).length; index1++) {
        for (let index2 = 0; index2 < Object.values(summaryResponse)[index1].length; index2++) {
          Object.assign(Object.values(summaryResponse)[index1][index2], { key: Object.keys(summaryResponse)[index1] });
          arr.push(Object.values(summaryResponse)[index1][index2]);
        }
      }
    }
    let totalArr = [];
    if (tabData.enablenestedtab === "Y") {
      for (let index = 0; index < columnsData.length + 2; index++) {
        totalArr[index] = 0;
      }
    } else {
      for (let index = 0; index < columnsData.length + 1; index++) {
        totalArr[index] = 0;
      }
    }
    for (let index = 0; index < columnsData.length; index++) {
      if (columnsData[index].baseReferenceId === "22" || columnsData[index].baseReferenceId === "10") {
        if (tabData.enablenestedtab === "Y") {
          totalArr[index + 2] = columnsData[index].dataIndex.replace("_iden", "");
        } else {
          totalArr[index + 1] = columnsData[index].dataIndex.replace("_iden", "");
        }
      }
    }
    if (tabData.enablenestedtab === "Y") {
      totalArr.splice(2, 1, "key");
    } else {
      totalArr.splice(1, 1, "key");
    }
    let finalArr = [];
    for (let index1 = 0; index1 < arr.length; index1++) {
      let tempArr = [];
      for (let index2 = 0; index2 < Object.keys(arr[index1]).length; index2++) {
        for (let index3 = 0; index3 < totalArr.length; index3++) {
          if (totalArr[index3] === Object.keys(arr[index1])[index2]) {
            tempArr[index3] = Object.values(arr[index1])[index2];
          }
        }
      }
      finalArr[index1] = tempArr;
    }
    for (let index1 = 0; index1 < finalArr.length; index1++) {
      for (let index2 = 0; index2 < finalArr[index1].length; index2++) {
        if (finalArr[index1][index2] === undefined) {
          finalArr[index1][index2] = "";
        }
      }
    }
    return (
      <Table.Summary fixed>
        {finalArr.map((data, index) => {
          return (
            <Table.Summary.Row key={index}>
              {data.map((summaryItem, index) => {
                return (
                  <Table.Summary.Cell key={index}>
                    <Text>{summaryItem}</Text>
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          );
        })}
      </Table.Summary>
    );
  };

  const columns = columnsData.map((col, index) => ({
    ...col,
    onHeaderCell: (columns) => ({
      width: columns.width,
      onResize: handleResize(index),
    }),
  }));
  const handleResize =
    (index) =>
      (e, { size }) => {
        setColumnsData((columns) => {
          const nextColumns = [...columns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          return nextColumns;
        });
      };

  useEffect(() => {
    const hasFilterApplied = columnsData.some((col) => col.filteredValue !== null && col.filteredValue !== undefined);
    setClearFiltersFlag(hasFilterApplied);
  }, [columnsData]);

  useEffect(() => {
    let arr = [];
    expandTreeViewData.forEach((data, index) => {
      arr.push(data.key);
    });
    setKeys(arr);
  }, [expandTreeViewFlag]);

  useEffect(() => {
    setKeys([]);
  }, [collapseTreeViewFlag]);

  if (displayKanban === true) {
    localStorage.setItem("windowType", "kanban")
  } else {
    localStorage.setItem("windowType", null)
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const innerWidth = windowSize.innerWidth



  const groupedData = dataSourceRecords.reduce((acc, record) => {
    // console.log(acc,"---acc")

    let groupKey = record[headerTab.rowlevel_groupbyfield_id];

    // Check if the field is a date field by checking the base reference ID
    const isDateField = headerTab.fields.some(
      field => field.ad_field_id === headerTab.rowlevel_groupbyfield_id && field.nt_base_reference_id === FieldReference.Date // Replace with actual reference ID
    );
    const fieldValueIden =
    (headerTab.rowlevel_groupbyfield_id && record[headerTab.rowlevel_groupbyfield_id.concat("_iden")]) ||
    record[headerTab.rowlevel_groupbyfield_id] || null; // Fallback to null if neither condition is met
  


    if (isDateField) {
      // Extract only the date part, ignoring time
      groupKey = groupKey?.split(' ')[0]; // This will keep only the date (YYYY-MM-DD)
    } else {
      groupKey = fieldValueIden
    }

    if (!acc[groupKey]) {
      // console.log(!acc[groupKey],"---->")
      acc[groupKey] = [];
    }
    acc[groupKey].push(record);
    return acc;

  }, {});


  return (
    <div
      style={{
        paddingLeft: "",
        paddingRight: "",
        maxHeight: "70vh",
        overflowY: "auto"
      }}
      ref={containerRef}
      className="custom-scrollbar"
    >
      <style>
        {`
        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 3px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #C1C1C1;
          border-radius: 3px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #7D7D7D;
        }
        `}
      </style>
      {Object.keys(groupedData).map((groupKey, index, array) => (
        <div key={groupKey} >
          <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 500 }}>{groupKey}</span>
          {tabData.enablenestedtab === "Y" ? (
            <ReactDragListView.DragColumn {...dragProps}>
              <style>
                {/* Scrollbar styling */}
                {`
              div::-webkit-scrollbar {
                width: 8px;
                height: 8px;
              }
              div::-webkit-scrollbar-track {
                background-color: #f1f1f1;
              }
              div::-webkit-scrollbar-thumb {
                background-color: #C1C1C1;
                border-radius: 4px;
              }
              div::-webkit-scrollbar-thumb:hover {
                background-color: #7D7D7D;
              }
              `}
              </style>
              <Table
                ref={(array.length === 1 && index === 0) || (array.length > 1 && index === array.length - 1) ? lastTableRef : null}
                style={{ fontFamily: "Inter", paddingTop: "7px" }}
                size="small"
                className="mainTable"
                scroll={{ y: innerWidth < 768 ? "58vh" : innerWidth >= 1700 ? "77vh" : innerWidth >= 1600 ? "75vh" : "30vh", x: "100%" }}
                sticky={true}
                pagination={false}
                loading={{
                  spinning: (array.length === 1 && index === 0) || (array.length > 1 && index === array.length - 1) ? loading : false,
                  indicator: <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />,
                }}
                dataSource={groupedData[groupKey]}
                columns={columns}
                components={components}
                rowSelection={rowSelection}
                onRow={(record) => ({
                  onClick: () => {
                    localStorage.setItem('record', JSON.stringify(record));
                    history.push(`/window/${windowDefinition.ad_window_id}/${record.recordId}`);
                  },
                })}
                expandedRowRender={expandedRowRender}
                expandable={{
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <img src={ExpandIcon} style={{ cursor: 'pointer', transform: `rotate(90deg)` }} onClick={(e) => { onExpand(record, e); e.stopPropagation(); }} />
                    ) : (
                      <img src={ExpandIcon} style={{ cursor: 'pointer' }} onClick={(e) => { onExpand(record, e); e.stopPropagation(); }} />
                    ),
                }}
                onExpand={handleExpand}
                onChange={handleTableChange}
                expandedRowKeys={nestedKeys}
                summary={summary}
              />
            </ReactDragListView.DragColumn>
          ) : (
            <ReactDragListView.DragColumn {...dragProps}>
              <style>
                {/* Scrollbar styling */}
                {`
              div::-webkit-scrollbar {
                width: 8px;
                height: 8px;
              }
              div::-webkit-scrollbar-track {
                background-color: #f1f1f1;
              }
              div::-webkit-scrollbar-thumb {
                background-color: #C1C1C1;
                border-radius: 4px;
              }
              div::-webkit-scrollbar-thumb:hover {
                background-color: #7D7D7D;
              }
              `}
              </style>
              <Table
                ref={(array.length === 1 && index === 0) || (array.length > 1 && index === array.length - 1) ? lastTableRef : null}
                style={{ fontFamily: "Inter", paddingTop: "7px" }}
                size="small"
                className="mainTable"
                scroll={{ y: innerWidth < 768 ? "58vh" : innerWidth >= 1700 ? "77vh" : innerWidth >= 1600 ? "75vh" : "30vh", x: "100%" }}
                sticky={true}
                pagination={false}
                loading={{
                  spinning: (array.length === 1 && index === 0) || (array.length > 1 && index === array.length - 1) ? loading : false,
                  indicator: <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />,
                }}
                dataSource={groupedData[groupKey]}
                columns={columns}
                components={components}
                rowSelection={rowSelection}
                onRow={(record) => ({
                  onClick: () => {
                    localStorage.setItem('record', JSON.stringify(record));
                    history.push(`/window/${windowDefinition.ad_window_id}/${record.recordId}`);
                  },
                })}
                onChange={handleTableChange}
                onExpand={handleOnExpand}
                expandedRowKeys={keys}
                summary={summary}
              />
            </ReactDragListView.DragColumn>
          )}
        </div>
      ))}
    </div>

  );
};

export default FieldGrouping;
