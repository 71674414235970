import { Card, Checkbox, Col, Input, Row, Select, Button, Form, message, Spin, InputNumber } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from 'react';
import "./styles.css"
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { getOAuthHeaders } from '../../../constants/oAuthValidation';
import Scrollbars from 'react-custom-scrollbars';
import { getTabData } from '../../../services/generic';
import formFieldsJson from './formFields.json';

const Preferences = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [mainForm] = Form.useForm();
    const formData =   mainForm.getFieldsValue()
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const serverUrl = process.env.REACT_APP_serverUrl;
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split('/');
    const recordId = pathSegments[pathSegments.length - 1];
    const [checkedFields, setCheckedFields] = useState({});
    const [nestedCheckedFields, setNestedCheckedFields] = useState({});
    const [numberInputValue, setNumberInputValue] = useState('');
    const [feedbackList, setFeedbackList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [posType, setPosType] = useState(null);
    const genericUrl = process.env.REACT_APP_genericUrl;
    const { Option } = Select;

    useEffect(async () => {
        let headerRecordData;
        if (recordId !== "NEW_RECORD") {
            const getTabDataResponse = await getTabData({ windowId: "7585", ad_tab_id: "CA24F0DE019C40BC962E1F03B532F1A7", recordId: recordId, startRow: "0", endRow: "1" });
            headerRecordData = getTabDataResponse[0];
            getConfigData(headerRecordData.EE8F1047056B4CA9B4FB899BF527BF56);
        }
    }, []);

    const onFinish = (values, e) => {
        
        for (const prop in values) {
            if (values[prop] === true) {
                values[prop] = "Y";
            } else if (values[prop] === false || values[prop] === undefined) {
                values[prop] = "N";
            }
        }
        if (values.showCustomerSearch === "N" || values.showCustomerSearch === "false") {
            values.showCustomerSearch = "Do Not Show"
        }
        values.printReceipt = "N";
        values.addLoyaltyLevel = "N";
        values.loyaltyLevelID = null;

        const filteredValues = { ...values };
        delete filteredValues.name;
        delete filteredValues.posType;
        delete filteredValues.activateExpiryDiscount;
        delete filteredValues.minimumOpeningAmount;
        const Fieldvalues = JSON.stringify(JSON.stringify(filteredValues));

        try {
            setLoading(true);
            const { access_token } = getOAuthHeaders();
            const posConfigArray = [];
            posConfigArray.push(

                `{
                application:"POS"
                name:${values.name !== "N" ? `"${values.name}"` : null}
                posType:${values.posType !== "N" ? `"${values.posType}"` : null}
                activateExpiryDiscount:"${values.activateExpiryDiscount}"
                minimumOpeningAmount: ${values.minimumOpeningAmount !== "N" && values.minimumOpeningAmount ? values.minimumOpeningAmount : null}
                cwrFeedbackId:${selectedOption ? `"${selectedOption}"` : null},
                configJson: ${Fieldvalues}
            },
            `

            );

            const posConfigMutation = {
                query: `mutation {
              upsertPOSConfig(posConfig: {
                cwrPosConfigs: [${posConfigArray}]
              }) {
                status
                message
              }
            }`,
            };

            Axios({
                url: serverUrl,
                method: "POST",
                data: posConfigMutation,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${access_token}`,
                },
            }).then((response) => {
                const posConfigResponse = response.data.data.upsertPOSConfig;
                if (posConfigResponse.status === "200") {
                    message.success(posConfigResponse.message);
                    setLoading(false);
                    setIsFormChanged(false)
                } else {
                    message.error(posConfigResponse.message);
                    setLoading(false);
                }
            });
        } catch (error) {
            console.log(error)
        }

    };

    const getConfigData = async (recordName) => {
        try {
            setLoading(true);
            const { access_token } = getOAuthHeaders();
            const getPOSConfigMutation = {
                query: `query{
              getPOSConfig(tillId:null , name: "${recordName}")
              {
                cwrPosConfigId
                name
                posType
                application
                configJson
                PricingRule
                ExpiryDiscount
                activateExpiryDiscount
                minimumOpeningAmount
                feedback
              }
            }`,
            };

            const headers = {
                "Content-Type": "application/json",
                Authorization: `bearer ${access_token}`,
            };

            const PosResponse = await Axios.post(serverUrl, getPOSConfigMutation, { headers: headers }, { async: true }, { crossDomain: true });

            if (PosResponse.status === 200) {
                setLoading(false)
                const posConfigResponse = PosResponse.data.data.getPOSConfig.filter(val => val.cwrPosConfigId === recordId);
                if (posConfigResponse[0]?.posType === 'PRD') {
                    const parentSection = formFieldsJson.find(section =>
                        section.fields.some(field => field.name === 'posType')
                    );
                    setPosType(posConfigResponse[0]?.posType)
                } else {
                    setPosType(null)
                }
                const stringifiedConfig = JSON.parse(posConfigResponse[0].configJson);
                const posParsedConfigJSON = stringifiedConfig;
                const updatedCheckedFields = {};
                for (const [key, value] of Object.entries(posParsedConfigJSON)) {
                    if (value === 'Y') {
                        updatedCheckedFields[key] = true;
                    } else if (value !== 'N' && value !== 'Y') {
                        updatedCheckedFields[key] = value;
                    }

                }
                setCheckedFields(updatedCheckedFields);
                setNestedCheckedFields(updatedCheckedFields)
                setSelectedOption(posParsedConfigJSON.feedbackTemplate)
                mainForm.setFieldsValue({
                    ...updatedCheckedFields,
                    posType: posConfigResponse[0].posType ? posConfigResponse[0].posType : "",
                    name: posConfigResponse[0].name ? posConfigResponse[0].name : "",
                    minimumOpeningAmount: posConfigResponse[0].minimumOpeningAmount ? posConfigResponse[0].minimumOpeningAmount : "",
                    activateExpiryDiscount: posConfigResponse[0].activateExpiryDiscount && posConfigResponse[0].activateExpiryDiscount === "Y" ? true : false,
                    feedbackTemplate: posConfigResponse[0].feedback ? JSON.parse(posConfigResponse[0].feedback).name : ""
                }
                )

            }
        } catch (error) {
            console.log(error)
        }
        mainForm.validateFields()
            .then(values => {
                console.log(values)
                setInitialValues(values)
            });
    };
    const onchangeCheckBox1 = (fieldName) => (e) => {
        setNestedCheckedFields({ ...nestedCheckedFields, [fieldName]: e.target.checked });

    }
    const onchangeCheckBox = (fieldName) => (e) => {
        setCheckedFields({ ...checkedFields, [fieldName]: e.target.checked });

    }
    const handleFormChange = (values) => {
        setIsFormChanged(true)

    };
    const onClickCancel = () => {
        mainForm.resetFields()
        setIsFormChanged(false)
    }

    const renderThumb = ({ style, ...props }) => {
        const thumbStyle = {
            backgroundColor: "#c1c1c1",
            borderRadius: "5px",
            width: "8px",
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };

    const renderThumbHorizontalScroll = ({ style, ...props }) => {
        const thumbStyle = {
            // backgroundColor: "#c1c1c1",
            // borderRadius: "5px",
            width: "0px",
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };

    const renderView = ({ style, ...props }) => {
        const viewStyle = {
            color: "#00000",
        };
        return <div className="box" style={{ ...style, ...viewStyle, overflowX: "hidden", marginBottom: "0" }} {...props} />;
    };

    const handleKeyDown = (event) => {
        const key = event.key;
        // Allow backspace, delete, navigation keys, and integers
        if (
            key === 'Backspace' ||
            key === 'Delete' ||
            (key >= '0' && key <= '9') ||
            ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)
        ) {
            return;
        }
        event.preventDefault(); // Prevent invalid characters
    };
    const getDropdownList = async (fieldName) => {
        try {
            const { access_token } = getOAuthHeaders();
            let query;

            if (fieldName === 'feedbackTemplate') {
                query = `query { comboFill(tableName:"cwr_feedback_template", pkName:"cwr_feedback_template_id", identifier:"name", whereClause:"isactive='Y'") }`;
            }

            if (query) {  // Check if query exists
                const getDropdownList = { query };
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${access_token}`,
                };

                const serverResponse = await Axios.post(genericUrl, getDropdownList, { headers: headers });

                const parsedData = JSON.parse(serverResponse.data.data.comboFill);
                const formattedData = parsedData.map(item => ({
                    label: item.name,
                    value: item.recordid
                }));

                setFeedbackList(formattedData);
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const onselectChange = (val) => {
        if (val === 'PRD' || formData?.posType === 'PRD' ) {
            setSelectedOption(val)
            setPosType(val)
           
            const fieldsToReset = mainForm.getFieldsValue();
        
            // Specify the keys to retain
            const fieldsToExclude = ['name', 'posType', 'enableProduction'];
    
            // Create a new object with fields reset to undefined except for excluded keys
            const newFieldValues = Object.keys(fieldsToReset).reduce((acc, key) => {
                if (fieldsToExclude.includes(key)) {
                    acc[key] = fieldsToReset[key]; // Retain original value
                } else {
                    acc[key] = undefined; // Reset other fields to undefined
                }
                return acc;
            }, {});
    
            // Set the form fields with the new values
            mainForm.setFieldsValue(newFieldValues);
    
            // Set enableProduction to true
            mainForm.setFieldsValue({ enableProduction: true });
        }
        else {
            setSelectedOption(val)
            setPosType(null)
            mainForm.setFieldsValue({ enableProduction: false });
        }
    }
    return (
        <Spin indicator={<LoadingOutlined style={{ fontSize: "52px" }} spin />} spinning={loading}>
            <Scrollbars
                autoHide={false}
                thumbSize={100}
                renderView={renderView}
                renderThumbHorizontal={renderThumbHorizontalScroll}
                renderThumbVertical={renderThumb}
                style={{ height: "77vh" }}>
                <Form onFinish={onFinish} form={mainForm} name='mainForm'
                    initialValues={initialValues}
                    onValuesChange={handleFormChange}>
                    {formFieldsJson.map(section => (
                        <div key={section.name}   style={{ display: formData?.posType === 'PRD' ? (section.showInPRDLayout ? 'block' : 'none') : 'block' }}>
                            <div className='pref-headings-div'>
                                <span className='pref-headings'>{section.name}</span>
                            </div>
                            <Card>
                                <Row style={{ display: "flex" }} justify="space-between" align='top'>
                                    {section.fields.map(field => {
                                        switch (field.type) {
                                            case 'input':
                                                return (
                                                    <Col xs={24} sm={12} className='pref-card-div' style={{ display: formData?.posType === 'PRD' ? (field.showInPRDLayout ? 'block' : 'none') : 'block' }}>
                                                        <span className='lable-text' style={{ marginRight: "0.5rem" }}>{field.label}</span>
                                                        <Form.Item name={field.name} noStyle={true} >
                                                            <Input className='inside-text' style={{ width: "10vw", borderRadius: "5px" }} />
                                                        </Form.Item>
                                                    </Col>
                                                );
                                            case 'textArea':
                                                return (
                                                    <Col xs={24} sm={12} className='pref-card-div' style={{ display: formData?.posType === 'PRD' ? (field.showInPRDLayout ? 'block' : 'none') : 'block' }}>
                                                        <span className='lable-text' style={{ marginRight: "0.5rem" }}>{field.label}</span>
                                                        <Form.Item name={field.name} noStyle={true}>
                                                            <Input.TextArea size='large' className='inside-text' style={{ borderRadius: "5px" }} />
                                                        </Form.Item>
                                                    </Col>
                                                );
                                            case 'number':
                                                return (
                                                    <Col xs={24} sm={12} className='pref-card-div' style={{ display: formData?.posType === 'PRD' ? (field.showInPRDLayout ? 'block' : 'none') : 'block' }}>
                                                        <span className='lable-text' style={{ marginRight: "0.5rem" }}>{field.label}</span>
                                                        <Form.Item name={field.name} noStyle={true}>
                                                            <InputNumber onKeyDown={handleKeyDown} value={numberInputValue} type='number' className='inside-text' style={{ width: "10vw", borderRadius: "5px" }} />
                                                        </Form.Item>
                                                    </Col>
                                                );
                                            case 'select':
                                                return (
                                                    <Col xs={24} sm={12} className='pref-card-div' style={{ display: formData?.posType === 'PRD' ? (field.showInPRDLayout ? 'block' : 'none') : 'block' }}>
                                                        <span className='lable-text' style={{ marginRight: "0.5rem" }}>{field.label}</span>
                                                        <Form.Item name={field.name} noStyle={true}>
                                                            <Select
                                                                className='inside-text'
                                                                onFocus={() => {
                                                                    if (!field.options) {
                                                                        getDropdownList(field.name);
                                                                    }
                                                                }}
                                                                loading={loading}
                                                                onChange={(val) => { onselectChange(val) }}
                                                                value={selectedOption}
                                                            >
                                                                {(field.options || feedbackList).map(option => (
                                                                    <Option key={option.value} value={option.value}>{option.label}</Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                );
                                            case 'checkbox':
                                                return (
                                                    <>
                                                        <Col xs={24} sm={12} className='pref-card-div' style={{ display: formData?.posType === 'PRD' ? (field.showInPRDLayout ? 'block' : 'none') : 'block' }}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Form.Item name={field.name} valuePropName="checked" noStyle={true}>
                                                                        <Checkbox checked={checkedFields[field.name]} style={{ marginRight: "1rem" }} onChange={onchangeCheckBox(field.name)} />
                                                                    </Form.Item>
                                                                    <span className='lable-text'>{field.label}</span>
                                                                </Col>
                                                                {field.nestedFields && (checkedFields[field.name]) && (
                                                                    <>
                                                                        {field.nestedFields.map(nestedField => (
                                                                            <Col span={12} key={nestedField.name} style={{ paddingLeft: "2rem", minWidth: "15vw", display: "flex", alignItems: "center" }}>
                                                                                {nestedField.type === 'checkbox' && (
                                                                                    <>
                                                                                        <Form.Item name={nestedField.name} valuePropName="checked" noStyle={true}>
                                                                                            <Checkbox checked={nestedCheckedFields[field.name]} style={{ marginRight: "1rem" }} onChange={onchangeCheckBox1(nestedField.name)} />
                                                                                        </Form.Item>
                                                                                        {nestedField.label && (
                                                                                            <span
                                                                                                className='label-text'
                                                                                                style={{
                                                                                                    display: 'inline-block',
                                                                                                    width: '118px', // set a common width for all labels
                                                                                                    whiteSpace: 'normal', // allow text wrapping
                                                                                                    wordWrap: 'break-word', // break long words if necessary

                                                                                                }}
                                                                                            >
                                                                                                {nestedField.label}
                                                                                            </span>
                                                                                        )}

                                                                                        {nestedField.nestedFields && nestedCheckedFields[nestedField.name] && (
                                                                                            <>
                                                                                                {nestedField.nestedFields.map(nestedField1 => (
                                                                                                    <Form.Item name={nestedField1.name} noStyle={true}>
                                                                                                        <Select className='inside-text' style={{ margin: "0.5em" }}>
                                                                                                            {nestedField1?.options?.map(option => (
                                                                                                                <Option key={option.value} value={option.value}>{option.label}</Option>
                                                                                                            ))}
                                                                                                        </Select>
                                                                                                    </Form.Item>
                                                                                                ))}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                                {nestedField.type === 'select' && (
                                                                                    <>
                                                                                        {nestedField.label && (<span className='lable-text' style={{ marginRight: "1rem" }}>{nestedField.label}</span>)}
                                                                                        <Form.Item name={nestedField.name} noStyle={true} style={{ float: "right" }}>
                                                                                            <Select className='inside-text' style={{ margin: "0.5em 0" }}>
                                                                                                {nestedField?.options?.map(option => (
                                                                                                    <Option key={option.value} value={option.value}>{option.label}</Option>
                                                                                                ))}
                                                                                            </Select>
                                                                                        </Form.Item>
                                                                                    </>
                                                                                )}
                                                                                {nestedField.type === 'input' && (
                                                                                    <>
                                                                                        {nestedField.label && (<span className='lable-text' style={{ marginRight: "1rem" }}>{nestedField.label}</span>)}
                                                                                        <Form.Item name={nestedField.name} noStyle={true}>
                                                                                            <Input className='inside-text' style={{ width: "10vw", borderRadius: "5px" }} />
                                                                                        </Form.Item>
                                                                                    </>
                                                                                )}

                                                                                {/* Conditional Select for checkMandatoryOrNot */}
                                                                                {nestedField.checkMandatoryOrNot === "Y" && nestedCheckedFields[nestedField.name] && (
                                                                                    <Form.Item name={`${nestedField.name}_mandatory`} noStyle={true} style={{ marginLeft: "1rem" }}>
                                                                                        <Select className='inside-text' style={{ width: "110px", marginLeft: "0.5rem", marginBottom: "1em" }}>
                                                                                            <Option value="Yes">Mandatory</Option>
                                                                                            <Option value="No">Optional</Option>
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                )}
                                                                            </Col>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </Row>

                                                        </Col>



                                                    </>
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                </Row>
                            </Card>
                        </div>
                    ))}
                    {isFormChanged ?
                        <Form.Item noStyle={true} style={{ marginTop: "1em" }}>
                            <Button onClick={onClickCancel} style={{ borderRadius: "4px", float: "right", fontSize: "16px", fontWeight: 700, fontFamily: "Inter", marginTop: "1em" }}>Cancel</Button>
                            <Button type="primary" htmlType="submit" style={{ background: "#0C173A", color: "#FFFFFF", borderRadius: "4px", float: "right", fontSize: "16px", fontWeight: 700, fontFamily: "Inter", marginRight: "0.5rem", marginTop: "1em" }}>Save</Button>
                        </Form.Item>

                        :
                        <Button onClick={() => history.push('/window/list/7585')} style={{ borderRadius: "4px", float: "right", fontSize: "16px", fontWeight: 700, fontFamily: "Inter", marginTop: "1em" }}>Close</Button>

                    }
                </Form>
            </Scrollbars>
        </Spin>
    )
}
export default Preferences;
